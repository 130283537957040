<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">{{title}} 찾기<br/><span>{{title}}를 분실 하셨나요?</span></div>
				</div>
				<div class="contents-body cf">
					<div class="contents-body-box">
						<img src="/images/mem/phone_img.png" alt="휴대폰본인인증"/>
						<div class="guide-text">
							<span>휴대폰 본인인증</span><br/>
							본인인증을 진행해 주세요.<br/>
							<div v-if="title == '아이디'">휴대폰 인증 후 아이디를 확인할 수 있습니다.</div>
							<div v-else>휴대폰 인증 후 비밀번호를 재설정 할 수 있습니다.</div>
						</div>
						<div class="confirm-btn" @click="openCert()">
							휴대폰 본인인증 하기
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberId : '',
			title : '아이디',
			kcpcert : {},
			mberDivCd : '',
		}
	},

	beforeMount() {
		//console.log('ROUTE', this.$route);
		//console.log('PARAMETER', this.$route.params);

		if(Object.keys(this.$route.params).length > 0) {
			var viewNm = this.$route.name;

			if(viewNm == 'MEM002M05') {
				this.title = '비밀번호';
				this.mberId = this.$route.params.mberId;
			}else {
				this.mberDivCd = this.$route.params.mberDivCd;
			}
		}else {
			this.$router.replace('MEM001M01');
		}
	},

	methods : {
		setForwardData() {
		},

		openCert() {
			this.$.kcpCert(this, function(this_obj, res) {
				this_obj.kcpcert = res;
				this_obj.memberChk();
			});

		},

		memberChk() {
			var param = this.kcpcert;
			if(this.mberId) param.mberId = this.mberId;
			if(this.mberDivCd) param.mberDivCd = this.mberDivCd;

			//console.log('MEMBER_CHK_REQ :', param);

			this.$.httpPost('api/mem/memberChk', param, '')
				.then(res => {
					//console.log('result :', res);

					if(res.data.resultCode == '000') {
						if(res.data.idMap) {
							this.pageMove(res.data.idMap.mberId);
						}else {
							if(this.title == '아이디') {
								alert('가입된 아이디를 찾을 수 없습니다.');
							}else {
								alert('본인인증 내역과 아이디가 일치하지 않습니다.');
							}
						}
					}
				});
		},

		pageMove(id) {
			var scrNm = 'MEM002M04';
			if(this.title == '비밀번호') scrNm = 'MEM002M03';

			var param = {
				title : this.title,
				mberId : id
			}

			this.$router.push({name:scrNm, params:{forwardData : param}});
		}
	}
}
</script>